// deno-lint-ignore-file no-explicit-any

import { Signal } from "@preact/signals"

interface obj {
  [key: string]: any
}

export const changeSignalObj = (s: Signal<obj>):
  (k:string, f:(_:any) => any) => void => {
  return (k: string, f: (_:any) => any) => {
    const v: obj = {...s.value}
    v[k] = f(s.value[k])
    s.value = v
  }
}